body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#jumboHeader {
    height: 400px;
    background: url('https://images.pexels.com/photos/34088/pexels-photo.jpg?w=940&h=650&auto=compress&cs=tinysrgb') no-repeat center;
    background-size: cover;
    position: relative;
}

body {
    background-color: lightgrey;
}

div > a > i {
    color: white;
}

div > a > i:hover {
    color: #0d47a1;
}

.projectHeading {
    background-color: #F5F5F5;
}

#navbar-padding-style {
    padding-top: 64px;
}

@media only screen and (max-width: 593px) {
    /* For Phones: */
    #navbar-padding-style {
        padding-top: 56px;
    }
}

.indicator {
    background-color: #0D47A1 !important;
}

li .active {
    background-color: rgba(13, 71, 161, 0.2) !important;
}
#profile-picture {
    width: 15%;
    border-radius: 100%;
    border-color: #424242;
    border-style: solid;
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    left: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {
    /* For Phones: */
    #profile-picture {
        width: 200px;
        margin-top: 20%;
    }
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    #profile-picture {
        width: 290px;
        margin-top:7%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    #profile-picture {
        width: 264px;
        border-radius: 100%;
        border-color: #424242;
        border-style: solid;
        z-index: 5;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3%;
        left: 0;
        right: 0;
    }
  }
