#profile-picture {
    width: 15%;
    border-radius: 100%;
    border-color: #424242;
    border-style: solid;
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    left: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {
    /* For Phones: */
    #profile-picture {
        width: 200px;
        margin-top: 20%;
    }
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    #profile-picture {
        width: 290px;
        margin-top:7%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    #profile-picture {
        width: 264px;
        border-radius: 100%;
        border-color: #424242;
        border-style: solid;
        z-index: 5;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3%;
        left: 0;
        right: 0;
    }
  }