#jumboHeader {
    height: 400px;
    background: url('https://images.pexels.com/photos/34088/pexels-photo.jpg?w=940&h=650&auto=compress&cs=tinysrgb') no-repeat center;
    background-size: cover;
    position: relative;
}

body {
    background-color: lightgrey;
}

div > a > i {
    color: white;
}

div > a > i:hover {
    color: #0d47a1;
}

.projectHeading {
    background-color: #F5F5F5;
}

#navbar-padding-style {
    padding-top: 64px;
}

@media only screen and (max-width: 593px) {
    /* For Phones: */
    #navbar-padding-style {
        padding-top: 56px;
    }
}

.indicator {
    background-color: #0D47A1 !important;
}

li .active {
    background-color: rgba(13, 71, 161, 0.2) !important;
}